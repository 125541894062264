.dateInput::-webkit-datetime-edit-day-field:focus,
.dateInput::-webkit-datetime-edit-month-field:focus,
.dateInput::-webkit-datetime-edit-year-field:focus {
  background-color: rgba(255, 255, 255, 0);
  color: #515151;
  outline: none;
}

.dateInput[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
