$base-color: #7e8c14;
$base-color-hover: #9fb118;
$text-color-grey: #2d2e40;
$secondary-color: #D9420B;
$secondary-light-color: #F1A663;
$footer-bg: #2D2E40;
$newsletter-text-color: #fff;
$newsletter-background-color: rgba(81, 89, 12, 0.61);
$newsletter-text-input-color: #fff;
$footer-text-color: #fff;
$copyright-background-color: #2D2E40;
$copyright-text-color: #fff;
$fixed-bar-background-color: #fff;
$searcher-container-background-color: #f3f3f3;
$searcher-container-text-color: #fff;
$header-mobile-bottom-like-color: #2D2E40;
$header-mobile-bottom-like-background: #FFFBF7;