@import "../../../_toronto/variables.scss";

.mini-carrito__icon {
  .MuiBadge-colorPrimary {
    background-color: #00ff66;
    color: black;
  }
}

.mini-carrito__container {
  display: flex;
  align-items: flex-end;
}
