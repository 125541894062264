@import "../../../_toronto/variables.scss";

.main-menu__container{
  ul{
    li{
      display: inline-block;
      margin: 0 10px;
      padding: 0 10px;
      list-style: none;
      position: relative;
      a{
        text-decoration: none;
        span{
          font-size: 14px;
          font-weight: 600;
          color: #333;
          -webkit-transition: all ease-in-out 0.5s;
          -moz-transition: all ease-in-out 0.5s;
          -ms-transition: all ease-in-out 0.5s;
          -o-transition: all ease-in-out 0.5s;
          transition: all ease-in-out 0.5s;
        }
      }
    }
  }
}

.main-menu__container ul li a span:before{
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 0px;
  height: 2px;
  background: $base-color;
  -webkit-transition: all .4s cubic-bezier(.77,0,.175,1);
  -moz-transition: all .4s cubic-bezier(.77,0,.175,1);
  -ms-transition: all .4s cubic-bezier(.77,0,.175,1);
  -o-transition: all .4s cubic-bezier(.77,0,.175,1);
  transition: all .4s cubic-bezier(.77,0,.175,1);
}
.main-menu__container ul li a:hover span:before{
  width: 100%;
}

.main-menu__container ul li a:hover span{
  color: $base-color
}