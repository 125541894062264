@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}

.main__container {
  display: flex;
  flex-direction: column;
  justify-content: "center";
  align-items: center;
  height: "100%";
}

.login__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: "100%";
  height: "100%";
  border-radius: "20px";
}
.bg {
  color: rgb(18, 63, 122);
  background-color: "#64247E";
}

.login__container--desc {
  width: "20px";
}

.login__container--form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login__container--form--input :focus {
  border: 3px solid rgb(255, 0, 0);
}
