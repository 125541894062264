@import '../../../_toronto/variables.scss';

.searcher__container{
  display: flex;
  position: relative;
  border: 2px solid $searcher-container-background-color;
  background-color: $searcher-container-background-color;
  width: 524px;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  .MuiFormLabel-root.Mui-focused{
    color: $base-color;
  }
}

.searcher__button__container{
  height: 100%;
  position: absolute;
  right: 0px;
  .MuiSvgIcon-root{
    font-size: 2rem;
  }
}

.searcher__button{
  height: 100%;
  .MuiInput-underline:before{
    border: none;
  }
  .MuiInput-underline:after{
    border: none;
  }
}

.searcher__container__divider{
  height: 40px;
  border-left: 2px solid #bfbfbf;
  position: absolute;
  left: 135px;
}

.searcher__select-categories{
  position: absolute;
  bottom: 0px;

  .MuiInput-underline:before{
    border: none;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before{
    border: none;
  }
  .MuiNativeSelect-select:focus {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0);
  }
}

.searcher__text-input{
  position: absolute;
  left: 145px;
  bottom: 8px;
  width: 310px;
  .MuiInput-underline:before{
    border: none;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before{
    border: none;
  }
  .MuiInput-underline:after{
    border: none;
  }
  #outlined-basic-label{
    width: 350px;
  }
}

.searcher__button__container{
  .MuiButton-containedPrimary{
    background-color: $base-color;
  }  
  .MuiButton-containedPrimary:hover {
    background-color: $base-color-hover;
  }  
}
