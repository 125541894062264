@import '../../../_toronto/variables.scss';

.header__main-bar__container{
  max-width: 1400px;
  margin: 0 auto;
}

.header__superior-bar{
  display: flex;
  justify-content: space-between;
  height: 90px;
  margin: 20px auto;
  align-items: center;
}

.header__superior-bar__profile{
  .MuiIcon-fontSizeLarge{
    font-size: 2rem;
  }
}

.header__superior-bar__profile-wishlist{
  .MuiBadge-root {
    vertical-align: baseline;
  }  
  .MuiBadge-colorPrimary{
    background-color: $base-color;
  }
}

.header__superior-bar__profile-person{
  margin-right: 15px;
}

.header__middle-bar{
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
}
