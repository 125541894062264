.floating {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.floatingIcon {
  animation-name: floatingIcon;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.animate__animated.animate__fadeInUp {
  --animate-duration: 1s;
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, -5px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

@keyframes floatingIcon {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(2px, 3px);
  }
  100% {
    transform: translate(0, -0px);
  }
}
