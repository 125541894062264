.grid__container{ 
  display: flex;  
}

.grid__filters__container{
  width: 209px;
  padding-top: 28px;
}

.grid__products__container{
  max-width: 1100px;
  display: flex;
  flex-wrap: wrap;
}