@import '../../../_toronto/variables.scss';

.footer__container{
  width: 100%;
  height: 400px;
  background-color: $footer-bg;
  min-width: 900px;

  div{
    color: $footer-text-color;
  }
}

.footer__payment-methods{
  margin: 0 auto;
  text-align: center;
}


.footer__map{
  margin: 0 auto;
}

.footer__title{
  font-size: 25px;
  font-weight: 400;
}

.footer__payment-methods-img{
  margin-top: 28px;
}

.footer__data-customer__text{
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 10px;
}

.footer__data-customer-service{
  max-width: 337px;
}

.footer__content{
  max-width: 1400px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

.footer__data-customer-service__content{
  display: flex;
}

.footer__data-customer-service__content__icons{
  margin-right: 10px;
}