@import '../../../_toronto/variables.scss';

.card-item__image{
  width: 250px;
  height: 250px;
  margin: 0 auto;
}

.card-item__container:hover{
  background: #FFFBF7 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  cursor: pointer;
  transition: all ease .5s
}
.card-item__container:hover .card-item__add-to-cart{
  opacity: 1;
  transform: translate(0, -50px);
  transition: all ease .6s;
}

.card-item__container{
  margin: 0 auto;
  width: 260px;
  height: 403px;
  padding-top: 28px;
}

.card-item__title{
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0px;
  color: $base-color;
  margin-top: 38px;
  font-size: 14px;
}

.card-item__description{
  margin-top: 15px;
  text-align: center;
  text-transform: uppercase;
  color: $text-color-grey;
  opacity: 0.65;
  font-size: 14px;
}

.card-item__price{
  margin-top: 10px;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  color: $text-color-grey;
}

.card-item__add-to-cart{
  width: 260px;
  height: 40px;
  border-radius: 10px;
  background-color: $secondary-color;
  color: #FFF;
  text-align: center;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 13px;
  opacity: 0;
}

.card-item__add-to-cart:hover {
  background-color: $secondary-light-color;
}

.card-item__image-mobile{
  width: 155px;
  height: 155px;
  margin: 0 auto;
}

.card-item__container-mobile{
  margin: 0 auto;
  width: 171px;
  height: 351px;
  padding-top: 28px;
}

.card-item__container-mobile:hover{
  background: #FFFBF7 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  cursor: pointer;
  transition: all ease .5s
}
.card-item__container-mobile:hover .card-item__add-to-cart-mobile{
  opacity: 1;
  transform: translate(0px, -10px);
  transition: all ease .6s;
}

.card-item__add-to-cart-mobile{
  width: 170px;
  height: 36px;
  border-radius: 5px;
  background-color: $secondary-color;
  color: #FFF;
  text-align: center;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 13px;
  opacity: 0;
}

.card-item__add-to-cart-mobile:hover {
  background-color: $secondary-light-color;
}